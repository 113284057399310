<template>
  <div class="DoemHOme">
    <div class="logo"></div>
    <div id="initScene"></div>
    <div id="Canvas" ref="Canvas"></div>
    <div :class="`navItem navItem${i}`" :ref="item.YText" v-for="(item, i) in navData" :key="i" @mouseover="mouseOver(item)" @mouseleave="mouseLeave(item)">
      <div class="title">
        <span class="YText">{{ item.YText }}</span>
        <span class="ZText">{{ item.ZText }}</span>
      </div>
      <div :class="`childItem childItem${ii} isShow${childItem.isShow}`" v-for="(childItem, ii) in item.Data" :key="ii">
        <div class="navCirce">
          <a v-if="childItem.isShow" class="navLink" :href="childItem.url" target="_blank" rel="noopener noreferrer"></a>
          <span class="navLink" v-else></span>
          <div class="title">
            <span class="YText" v-html="childItem.YText"></span>
          </div>
          <p class="ZText">{{ childItem.ZText }}</p>
          <div class="loader">
            <div class="face">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
export default {
  data() {
    return {
      navData: [
        {
          YText: 'Safety',
          ZText: '太空安全',
          Data: [
            {
              id: 3,
              YText: 'Caution',
              ZText: '太空资产碰撞分析',
              isShow: true,
              url: 'https://ads.aseem.cn/pzyj/?d=1#/',
            },
            {
              id: 2,
              YText: 'Transat',
              ZText: '太空资产过境分析',
              isShow: true,
              url: 'https://ads.aseem.cn/transit/?d=1#/',
            },
            {
              id: 7,
              YText: 'Monitoring',
              ZText: '太空资产波束检测',
              isShow: true,
              url: 'https://ads.aseem.cn/digit/bsjc?d=1',
            },
            {
              id: 10,
              YText: 'Approach',
              ZText: '太空资产碰撞热力图',
              isShow: true,
              url: 'https://ads.aseem.cn/visualization/approach/v1?d=1',
            },
            // {
            //   id: 14,
            //   YText: 'Transat',
            //   ZText: '太空资产过境预报V2',
            //   isShow: true,
            //   url: 'https://ads.aseem.cn/visualization/transit/v2/',
            // },
            {
              id: 15,
              YText: 'Transat',
              ZText: '太空资产过境预报',
              isShow: true,
              url: 'https://ads.aseem.cn/visualization/transit/v3/?d=1',
            },
          ],
        },
        {
          YText: 'Target',
          ZText: '太空目标',
          Data: [
            {
              id: 6,
              YText: 'Track',
              ZText: '太空资产轨迹溯源',
              isShow: true,
              url: 'https://ads.aseem.cn/digit/gjsy?d=1',
            },
            {
              id: 8,
              YText: 'Link',
              ZText: '太空资产星地链接',
              isShow: true,
              url: 'https://ads.aseem.cn/digit/xdlj?d=1',
            },
            {
              id: 13,
              YText: 'Simulation',
              ZText: '太空资产轨道仿真',
              isShow: true,
              url: 'https://ads.aseem.cn/visualization/track/v2?d=1',
            },
            // {
            //   id: 5,
            //   YText: "Simulation",
            //   ZText: "太空资产轨道仿真v1",
            //   isShow: true,
            //   url: "http://36.103.232.201:7106/webkepler/#/",
            // },
            {
              id: 9,
              YText: 'Point',
              ZText: '太空资产星下点轨迹',
              isShow: true,
              url: 'https://ads.aseem.cn/digit/xxd?d=1',
            },
          ],
        },
        {
          YText: 'Situation',
          ZText: '太空态势',
          Data: [
            {
              id: 4,
              YText: 'Situation',
              ZText: '太空资产态势分析',
              isShow: true,
              // url: "http://36.103.232.201:17223/",
              url: 'https://ssa.aseem.cn/?d=1',
            },
            {
              id: 11,
              YText: `Situation<br>V3`,
              ZText: '太空资产态势分析v3',
              isShow: true,
              url: 'https://ads.aseem.cn/visualization/situation/v3/?d=1',
            },
            {
              id: 16,
              YText: `Situation<br>V4`,
              ZText: '太空资产态势分析v4',
              isShow: true,
              url: 'https://ads.aseem.cn/visualization/creatunion?d=1',
            },
            {
              id: 12,
              YText: 'Analysis',
              ZText: '太空资产态势溯源',
              isShow: true,
              url: 'https://ads.aseem.cn/visualization/deduce/v1?d=1',
            },
            {
              id: 1,
              YText: 'Data',
              ZText: '太空资产编目数据',
              isShow: true,
              url: 'https://ads.aseem.cn/tsgz/?d=1',
            },
          ],
        },
      ],
      // 创建一个场景
      scene: null,
      // 创建一个相机
      camera: null,
      // 创建一个渲染器
      renderer: null,
      // 模型对象
      mesh: null,
      // 平面
      plane: null,
      // step
      step: 0,
      controls: null,
    };
  },
  mounted() {
    this.initEarth();
  },
  methods: {
    // 初始化
    initEarth() {
      // 初始化容器
      var content = this.$refs.Canvas;
      while (content.firstChild) {
        content.removeChild(content.firstChild);
      }
      // 创建一个场景
      this.scene = new THREE.Scene();
      // 创建几何体
      const geometry = new THREE.SphereGeometry(1, 32, 32);
      // 纹理加载器 （ 此处加载贴图 ）
      var texture = new THREE.TextureLoader().load(require('../assets/newImg/24.png'));
      // var texture = new THREE.TextureLoader().load(require('../assets/newImg/2.png'));
      // 几何体材质对象
      var material = new THREE.MeshLambertMaterial({
        map: texture,
      });
      // 创建网格模型对象
      this.mesh = new THREE.Mesh(geometry, material);
      //设置几何体位置
      this.mesh.position.x = 0;
      this.mesh.position.y = 0;
      this.mesh.position.z = 0;
      this.scene.add(this.mesh);

      // 聚光灯
      let spotLight = new THREE.SpotLight(0xffffff, 10, 0); //创建光源
      // 光源移动
      spotLight.position.set(-80, 80, -80);
      this.scene.add(spotLight); //在场景中添加光源
      let spotLightB = new THREE.SpotLight(0xfee69f, 10, 0); //创建光源
      spotLightB.position.set(80, -80, -60);
      this.scene.add(spotLightB); //在场景中添加光源

      // 创建点光源
      var point = new THREE.PointLight('#FFF');
      point.position.set(0, 10, 10);
      this.scene.add(point);
      this.scene.add(new THREE.PointLightHelper(point, 10));

      //创建环境光
      var ambient = new THREE.AmbientLight(0x444444);
      this.scene.add(ambient);

      // 创建一个相机
      this.camera = new THREE.PerspectiveCamera(60, content.clientWidth / content.clientHeight, 1, 100);
      this.camera.position.set(0, 0, 2);
      this.camera.lookAt(0, 0, 0);

      // 创建渲染器
      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      // 设置alpha透明系数
      this.renderer.setClearAlpha(0);
      this.renderer.setSize(content.clientWidth, content.clientHeight);
      //插入 dom 元素
      content.appendChild(this.renderer.domElement);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      //禁用缩放
      this.controls.enableZoom = false;
      //禁用旋转
      this.controls.enableRotate = false;
      //禁用平移
      this.controls.enablePan = false;
      this.controls.addEventListener('resize', this.render(), false);
    },
    render() {
      this.renderer.setSize(this.$refs.Canvas.clientWidth, this.$refs.Canvas.clientHeight);
      this.renderer.render(this.scene, this.camera);
      // 自动旋转动画
      this.mesh.rotateY(0.002);
      requestAnimationFrame(this.render);
    },
    mouseOver(val) {
      switch (val.YText) {
        case 'Safety':
          this.$refs.Target[0].style.background = `url("${require('@/assets/newImg/19.png')}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = '100% 100%';
          this.$refs.Situation[0].style.background = `url("${require('@/assets/newImg/20.png')}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = '100% 100%';
          break;
        case 'Target':
          this.$refs.Safety[0].style.background = `url("${require('@/assets/newImg/18.png')}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = '100% 100%';
          this.$refs.Situation[0].style.background = `url("${require('@/assets/newImg/20.png')}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = '100% 100%';
          break;
        case 'Situation':
          this.$refs.Safety[0].style.background = `url("${require('@/assets/newImg/18.png')}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = '100% 100%';
          this.$refs.Target[0].style.background = `url("${require('@/assets/newImg/19.png')}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = '100% 100%';
          break;
        default:
          break;
      }
    },
    mouseLeave(val) {
      switch (val.YText) {
        case 'Safety':
          this.$refs.Target[0].style.background = `url("${require('@/assets/newImg/16.png')}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = '100% 100%';
          this.$refs.Situation[0].style.background = `url("${require('@/assets/newImg/17.png')}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = '100% 100%';
          break;
        case 'Target':
          this.$refs.Safety[0].style.background = `url("${require('@/assets/newImg/15.png')}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = '100% 100%';
          this.$refs.Situation[0].style.background = `url("${require('@/assets/newImg/17.png')}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = '100% 100%';
          break;
        case 'Situation':
          this.$refs.Safety[0].style.background = `url("${require('@/assets/newImg/15.png')}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = '100% 100%';
          this.$refs.Target[0].style.background = `url("${require('@/assets/newImg/16.png')}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = '100% 100%';
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url('./css/webhall.less');
</style>

<style>
.dg.ac {
  display: none;
}
</style>
